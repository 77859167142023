import React from "react";
import { ModuleWrapper } from "../moduleWrapper";
import { SanityImage } from "@components/sanity/sanityImage";
import { PortableTextBlock } from "@components/sanity/portableTextBlock";

const CardGrid = ({ config, items }) => {
  console.log(items);

  return (
    <ModuleWrapper {...config}>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-10  px-gutter ">
        {items?.map((item) => (
          <div className="rounded-[10px] overflow-hidden  ">
            <div className="relative aspect-w-7 aspect-h-5 overflow-hidden  ">
              <SanityImage
                image={item.image}
                className="absolute top-0 left-0 object-cover w-full h-full"
              />
            </div>
            <div className="bg-white w-full h-full px-5">
              <h3 className="text-2xl text-red pt-[30px]">{item.header}</h3>
              <PortableTextBlock text={item.text} className="pt-[10px]" />
            </div>
          </div>
        ))}
      </div>
    </ModuleWrapper>
  );
};

export default CardGrid;
